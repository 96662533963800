import QuoteMark from "@/components/QuoteMark";
import clsx from "clsx";

type TestimonialCardProps = {
  primaryQuote: string;
  secondaryQuote?: string;
  attributionName?: string;
  attributionRole?: string;
};

const TestimonialCard = ({
  primaryQuote,
  secondaryQuote = "",
  attributionName = "",
  attributionRole = "",
}: TestimonialCardProps) => {
  // Helper function to convert newlines to <br /> elements
  const formatText = (text: string) => {
    return text.split("\n").map((line, i) => (
      <span key={i}>
        {line}
        {i !== text.split("\n").length - 1 && <br />}
      </span>
    ));
  };

  return (
    <div className={clsx("Testimonial", "flex h-full flex-col")}>
      <div className={clsx("mb-6 flex")}>
        <QuoteMark />
      </div>
      <div className={clsx("")}>
        <blockquote>
          <div className={clsx(`heading-md`, {})}>
            {formatText(primaryQuote)}
          </div>
        </blockquote>
      </div>
      <div className={clsx("py-6")}>{secondaryQuote}</div>
      {attributionName && (
        <div className={clsx("small-caps mt-auto")}>
          {formatText(attributionName)}
        </div>
      )}
      {attributionRole && (
        <div className={clsx("small-caps mt-auto")}>
          {formatText(attributionRole)}
        </div>
      )}
    </div>
  );
};

export default TestimonialCard;
