import Video from "@/components/Video";
import clsx from "clsx";

const VideoSection = ({
  videoUrl,
  autoPlay = false,
  controls = true,
  loop = false,
}) => {
  return (
    <div className={clsx("VideoSection", "container")}>
      <div className={clsx("grid grid-cols-24 sm:gap-8")}>
        <div className={clsx("col-span-24", {})}>
          <div className="relative">
            <Video
              videoUrl={videoUrl}
              autoPlay={autoPlay}
              controls={controls}
              loop={loop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
