import TestimonialCard from "@/components/TestimonialCard";
import { generateSwiperBreakpoints } from "@/lib/swiperUtils";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const TestimonialCards = ({ testimonials }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth > 1024) {
        setOffset(112);
      } else if (windowWidth > 768 && windowWidth < 1024) {
        setOffset(96);
      } else if (windowWidth <= 768) {
        setOffset(24);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  const breakpoints = generateSwiperBreakpoints({
    sm: {
      slidesPerView: 1,
      spaceBetween: 24,
    },
    md: {
      slidesPerView: 1.5,
      spaceBetween: 24,
    },
    lg: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    xl: {
      slidesPerView: 2.5,
      spaceBetween: 24,
    },
  });

  return (
    <div>
      <div className={clsx("")}>
        <Swiper
          slidesPerView={1}
          breakpoints={breakpoints}
          className={clsx("")}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          navigation
          slidesOffsetAfter={offset}
          slidesOffsetBefore={offset}
        >
          {testimonials.map((testimonial) => {
            return (
              <SwiperSlide
                key={testimonial.id}
                className={clsx("!flex !h-auto px-6 md:px-0")}
              >
                <_Testimonial testimonial={testimonial} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

const _Testimonial = ({ testimonial }) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  return (
    <div
      className={clsx(
        "rounded-lg bg-stone-dark p-12 transition duration-1000 ease-out dark:bg-black-light",
        {
          "translate-y-2 opacity-0": !inView,
          "translate-y-0 opacity-100": inView,
        },
      )}
      ref={ref}
    >
      <TestimonialCard
        primaryQuote={testimonial.primaryQuote}
        secondaryQuote={testimonial.secondaryQuote}
        attributionName={testimonial.attributionName}
        attributionRole={testimonial.attributionRole}
      />
    </div>
  );
};

export default TestimonialCards;
