import Button from "@/components/Button";
import nl2br from "@/lib/nl2br";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const LocationList = ({ locations }) => {
  return (
    <div className={clsx("LocationList")}>
      {locations.map((location, index) => {
        return (
          <_Location key={location.id} location={location} index={index} />
        );
      })}
    </div>
  );
};

const _Location = ({ location, index }) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  return (
    <div
      ref={ref}
      className={clsx(
        "LocationList__location",
        "container transition ease-out duration-500",
        {
          "translate-y-2 opacity-0": !inView,
          "translate-y-0 opacity-100": inView,
        },
      )}
      style={{ transitionDelay: `${index * 50}ms` }}
    >
      <div
        className={clsx(
          "grid grid-cols-24 border-t border-black/20 transition duration-300 ease-out dark:border-white/20 py-8 lg:gap-x-8 lg:py-16",
        )}
      >
        <div className={clsx("col-span-24 lg:col-span-8")}>
          <div className={clsx("heading-lg mb-6 lg:mb-0")}>
            {location.heading}
          </div>
        </div>
        <div className={clsx("col-span-12 md:col-span-8 lg:col-span-6")}>
          <div
            className={clsx("mb-4 lg:mb-0")}
            dangerouslySetInnerHTML={{ __html: nl2br(location.address) }}
          ></div>
        </div>
        <div className={clsx("col-span-12 md:col-span-8 lg:col-span-6")}>
          <div>
            <div>
              <a href={`tel:${location.phoneNumber}`}>{location.phoneNumber}</a>
            </div>
            <div>
              <a href={`mailto:${location.emailAddress}`}>
                {location.emailAddress}
              </a>
            </div>
          </div>
        </div>
        <div className={clsx("col-span-24 flex md:col-span-8 lg:col-span-4")}>
          <div className={clsx("mt-6 md:ml-auto md:mt-0")}>
            <Button href={location.mapUrl} label="Directions" size="lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationList;
