const QuoteMark = () => {
  return (
    <svg
      width="38"
      height="28"
      viewBox="0 0 38 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2112 12.6527C12.942 12.9651 14.3975 13.7852 15.5776 15.113C16.7578 16.3626 17.3478 18.0028 17.3478 20.0335C17.3478 22.3766 16.5217 24.2901 14.8696 25.7741C13.2174 27.258 11.2505 28 8.96895 28C6.13665 28 3.93376 26.9847 2.36026 24.954C0.786758 22.8452 0 20.2287 0 17.1046C0 12.3403 1.53417 8.43515 4.60249 5.38912C7.67081 2.26499 12.0373 0.468618 17.7019 0V3.16318C14.3975 3.6318 11.8012 4.72524 9.91304 6.44351C8.10352 8.08368 7.04141 10.1534 6.72672 12.6527H11.2112ZM31.5093 12.6527C33.2402 12.9651 34.6957 13.7852 35.8758 15.113C37.0559 16.3626 37.646 18.0028 37.646 20.0335C37.646 22.3766 36.8199 24.2901 35.1677 25.7741C33.5942 27.258 31.6273 28 29.2671 28C26.4348 28 24.2319 26.9847 22.6584 24.954C21.0849 22.8452 20.2981 20.2287 20.2981 17.1046C20.2981 12.3403 21.8323 8.43515 24.9006 5.38912C27.9689 2.26499 32.3354 0.468618 38 0V3.16318C34.7743 3.6318 32.1781 4.72524 30.2112 6.44351C28.323 8.08368 27.3002 10.1534 27.1429 12.6527H31.5093Z"
        fill="#EC000C"
      />
    </svg>
  );
};

export default QuoteMark;
