import Image from "@/components/WrappedImage";
import clsx from "clsx";

const ImageSection = ({ image, width, inlineAlignment = "full" }) => {
  if (!image) return null;

  return (
    <div className={clsx("ImageSection", `ImageSection--${width}`)}>
      <div className={clsx("relative")}>
        {width === "fullWidth" && (
          <div className={clsx("relative mx-auto w-full max-w-[1920px]")}>
            <div
              className={clsx(
                "aspect-16/9 lg:aspect-2.35/1 2xl:aspect-auto 2xl:h-[66.666vh]",
              )}
            >
              <Image
                src={image.url}
                alt={image.title}
                fill
                className={"object-cover"}
                sizes="100vw"
              />
            </div>
          </div>
        )}
        {width === "wide" && (
          <div className={clsx("container")}>
            <Image
              src={image.url}
              alt={image.title}
              width={image.width}
              height={image.height}
              className={clsx("h-auto w-full")}
            />
          </div>
        )}
        {width === "inline" && (
          <div className={clsx("container")}>
            <div className={clsx("mx-auto max-w-2xl")}>
              <div
                className={clsx("", {
                  "w-1/2": inlineAlignment !== "full",
                  "w-full": inlineAlignment === "full",
                  "mx-auto": inlineAlignment === "center",
                })}
              >
                <Image
                  src={image.url}
                  alt={image.title}
                  width={image.width}
                  height={image.height}
                  className={clsx("h-auto w-full")}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSection;
