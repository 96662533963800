import LogoCell from "@/components/LogoCell";
import clsx from "clsx";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const LogoRow = ({ logos }) => {
  const duplicatedLogos = logos
    .concat(logos)
    .concat(logos)
    .map((logo, i) => ({ ...logo, key: i }));

  return (
    <div>
      <Swiper
        centeredSlides
        className={clsx("")}
        loop
        modules={[Navigation, Pagination, A11y]}
        navigation
        slideToClickedSlide={false}
        slidesPerGroup={1}
        slidesPerView={2}
        spaceBetween={24}
        speed={1000}
        breakpoints={{
          768: {
            slidesPerView: 3.5,
          },
          1024: {
            slidesPerView: 4.5,
          },
          1280: {
            slidesPerView: 5.5,
          },
          1536: {
            slidesPerView: 6.5,
          },
        }}
      >
        {duplicatedLogos.map((logo) => {
          const image = logo.logo?.[0];

          return (
            <SwiperSlide key={logo.key} className={clsx("")}>
              <LogoCell
                image={image}
                sizes="(min-width: 1536px) calc(100vw / 6.5),
             (min-width: 1280px) calc(100vw / 5.5),
             (min-width: 1024px) calc(100vw / 4.5),
             (min-width: 768px) calc(100vw / 3.5),
             calc(100vw / 2)"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default LogoRow;
