import EntryCard from "@/components/EntryCard";
import { generateSwiperBreakpoints } from "@/lib/swiperUtils";
import clsx from "clsx";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const EntryCarousel = ({
  priority = false,
  entries,
}: {
  priority: boolean;
  entries: any[];
}) => {
  const duplicatedEntries = entries
    .concat(entries)
    .concat(entries)
    .map((entry, i) => ({ ...entry, key: i }));

  const breakpoints = generateSwiperBreakpoints({
    sm: {
      slidesPerView: 1.5,
      spaceBetween: 24,
    },
    lg: {
      slidesPerView: 2.5,
      spaceBetween: 24,
    },
    xl: {
      slidesPerView: 3.5,
      spaceBetween: 24,
    },
  });

  const sizes =
    "(max-width: 640px) 100vw, " +
    "(max-width: 1023px) 66.666vw, " +
    "(max-width: 1279px) 33.333vw, " +
    "26vw";

  return (
    <div className={clsx("EntryCarousel relative overflow-hidden")}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        breakpoints={breakpoints}
        className={clsx(
          "swiper-thumbs",
          "invisible",
          "[&.swiper-initialized]:visible",
        )}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation
        loop
        centeredSlides
      >
        {duplicatedEntries.map((entry, index) => {
          const image = entry.image?.[0];

          return (
            <SwiperSlide
              key={entry.key}
              className={clsx("w-full px-6 sm:px-0", {})}
            >
              <div className={clsx("flex h-full w-full items-center")}>
                <div className={clsx("relative w-full")}>
                  <EntryCard
                    heading={entry.title}
                    subHeading={entry.subHeading}
                    image={image}
                    href={`/${entry.uri}`}
                    sizes={sizes}
                    priority={
                      priority &&
                      (index < 2 || index === duplicatedEntries.length - 1)
                    }
                  />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default EntryCarousel;
