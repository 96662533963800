import { screens } from "@/tailwind-config.client";

const generateSwiperBreakpoints = (
  config: Record<string, { slidesPerView: number; spaceBetween: number }>,
) => {
  const breakpoints: Record<
    number,
    { slidesPerView: number; spaceBetween: number }
  > = {};

  for (let breakpoint in config) {
    if (breakpoint in screens) {
      const width = parseInt(screens[breakpoint]);
      if (width) {
        breakpoints[width] = config[breakpoint];
      }
    }
  }

  return breakpoints;
};

const getSizesFromBreakpoints = (
  config: ReturnType<typeof generateSwiperBreakpoints>,
) => {
  let sizes = "(max-width: 640px) 100vw,";

  for (let breakpoint in config) {
    const width = parseInt(breakpoint);
    const slidesPerView = config[breakpoint].slidesPerView;

    if (width && slidesPerView) {
      const size = 100 / slidesPerView; // size relative to viewport width
      sizes += `(min-width: ${width}px) ${size}vw,`;
    }
  }

  return sizes.slice(0, -1); // Remove trailing comma
};

export { generateSwiperBreakpoints, getSizesFromBreakpoints };
