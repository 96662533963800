import BodyText from "@/components/BodyText";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const TextColumns = ({ columns }) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  return (
    <div className={clsx("TextColumns", "")} ref={ref}>
      <div
        className={clsx(
          "",
          "container grid grid-cols-24 sm:gap-8 transition duration-1000 ease-out",
          {
            "translate-y-2 opacity-0": !inView,
            "translate-y-0 opacity-100": inView,
          },
        )}
      >
        {columns.map((column, idx) => {
          return (
            <div
              key={column.id}
              className={clsx("col-span-24 lg:col-span-8", {
                "col-start-1 lg:col-start-9": idx === 0,
              })}
            >
              <BodyText text={column.bodyText} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TextColumns;
