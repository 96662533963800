import AwardsList from "@/components/AwardsList";
import Button from "@/components/Button";
import EntryCarousel from "@/components/EntryCarousel";
import EntryGrid from "@/components/EntryGrid";
import EntryList from "@/components/EntryList";
import Heading from "@/components/Heading";
import Hero from "@/components/Hero";
import HorizontalRule from "@/components/HorizontalRule";
import ImageCarousel from "@/components/ImageCarousel";
import ImageSection from "@/components/ImageSection";
import LocationList from "@/components/LocationList";
import LogoRow from "@/components/LogoRow";
import ProfileGrid from "@/components/ProfileGrid";
import Spacer from "@/components/Spacer";
import TestimonialCards from "@/components/TestimonialCards";
import TextBlock from "@/components/TextBlock";
import TextColumns from "@/components/TextColumns";
import VideoSection from "@/components/VideoSection";
import clsx from "clsx";
import React from "react";

const PageSections = ({
  sections = [],
  pageEntry,
  bgClass,
  textClass,
}: {
  sections: any[];
  pageEntry: any;
  bgClass?: string;
  textClass?: string;
}): JSX.Element => {
  return (
    <div className={clsx("PageSections", "", {})}>
      {sections?.map((section, index) => {
        // We want to make sure that any images loaded for components that are likely high up in the page are loaded with priority
        const priority = index <= 3;

        return (
          <div
            key={section.id}
            className={clsx(
              "PageSections__section",
              `PageSections__section--${section.typeHandle}`,
            )}
          >
            <Section typeHandle={section.typeHandle}>
              <Component
                section={section}
                pageEntry={pageEntry}
                bgClass={bgClass}
                textClass={textClass}
                priority={priority}
              />
            </Section>
          </div>
        );
      })}
    </div>
  );
};

const Section = ({ children, typeHandle }): JSX.Element => {
  const className = clsx("PageSection", `PageSection--${typeHandle}`);

  return (
    <div className={className}>
      <div className={clsx("PageSection__content", "relative")}>{children}</div>
    </div>
  );
};

const Component = ({
  priority = false,
  section,
  pageEntry = {},
  bgClass,
  textClass,
}: {
  priority?: boolean;
  section: any;
  pageEntry: any;
  bgClass?: string;
  textClass?: string;
}) => {
  switch (section.typeHandle) {
    case "hero": {
      return (
        <Hero
          heading={section.heading}
          subHeading={section.subHeading}
          showScrollButton={section.showScrollButton}
        />
      );
    }
    case "textBlock": {
      return (
        <TextBlock
          heading={section.heading}
          text={section.text}
          target={section.target}
          textAlignment={section.textAlignment}
        />
      );
    }
    case "spacer": {
      return <Spacer size={section.size} />;
    }
    case "entryGrid": {
      return <EntryGrid entries={section.entries} />;
    }

    case "entryList": {
      return <EntryList entries={section.entries} />;
    }

    case "horizontalRule": {
      return (
        <HorizontalRule colorClass={section.backgroundColor?.[0]?.className} />
      );
    }

    case "imageCarousel": {
      return <ImageCarousel slides={section.imageCarousel} />;
    }

    case "entryCarousel": {
      return <EntryCarousel priority={priority} entries={section.entries} />;
    }

    case "logoRow": {
      return <LogoRow logos={section.logos} />;
    }

    case "imageSection": {
      return (
        <>
          <ImageSection
            image={section.image?.[0]}
            width={section.width}
            inlineAlignment={section.inlineAlignment}
            caption={section.caption}
          />
        </>
      );
    }

    case "heading": {
      return (
        <Heading
          size={section.size}
          element={section.headingElement}
          alignment={section.alignment}
          target={section.target}
          buttonTheme={bgClass === "red" ? "white" : "red"}
        >
          {section.heading}
        </Heading>
      );
    }

    case "testimonialCards": {
      return <TestimonialCards testimonials={section.testimonials} />;
    }

    case "textColumns": {
      return <TextColumns columns={section.textColumns} />;
    }

    case "button": {
      const alignment = section.alignment || "center";

      return (
        <div
          className={clsx("flex container", {
            "justify-start": alignment === "left",
            "justify-center": alignment === "center",
          })}
        >
          <Button target={section.target} size="lg" theme="red" />
        </div>
      );
    }

    case "imageSection": {
      return (
        <ImageSection image={section.image?.[0]} width={section.imageWidth} />
      );
    }

    case "profileGrid": {
      return <ProfileGrid profiles={section.profiles} />;
    }

    case "awardsList": {
      return <AwardsList awards={section.awards} />;
    }

    case "locationList": {
      return <LocationList locations={section.locations} />;
    }

    case "videoSection": {
      return (
        <VideoSection
          videoUrl={section.videoUrl}
          controls={!section.hideVideoControls}
          loop={section.loopVideo}
          autoPlay={section.autoplayVideo}
        />
      );
    }

    case "sectionGroup": {
      const bgClass = section.backgroundColor?.[0]?.className || "white";
      const textClass = section.backgroundColor?.[0]?.textClassName || "black";

      return (
        <div
          className={clsx(
            "PageSections__group",
            `PageSections__group--${bgClass}`,
            `bg-${bgClass}`,
            `text-${textClass}`,
          )}
        >
          <PageSections
            pageEntry={pageEntry}
            sections={section.children}
            bgClass={bgClass}
            textClass={textClass}
          />
        </div>
      );
    }

    case "repeatableSections": {
      let sections: any[] = [];

      section.repeatableSections?.forEach((repeatableSection: any) => {
        sections = [...sections, ...repeatableSection.pageSections];
      });
      return (
        <>
          <PageSections pageEntry={pageEntry} sections={sections} />
        </>
      );
    }

    default:
      return (
        <div className={clsx("mx-auto max-w-2xl")}>
          <div className={clsx("border border-dotted p-8 text-center")}>
            {section.typeHandle} component in development
          </div>
        </div>
      );
  }
};

export default PageSections;
