import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

const ProfileGrid = ({ profiles }) => {
  return (
    <div className={clsx("ProfileGrid", "")}>
      <div className={clsx("container")}>
        <div className={clsx("grid grid-cols-12 gap-y-8 sm:gap-8")}>
          {profiles.map((profile) => {
            return <_Profile key={profile.id} profile={profile} />;
          })}
        </div>
      </div>
    </div>
  );
};

const _Profile = ({ profile }) => {
  return (
    <div
      className={clsx("col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3")}
    >
      <Link
        href={`/profiles/${profile.userSlug}`}
        className={clsx("group relative block aspect-3/4 bg-red")}
      >
        {!!profile.photo && (
          <Image
            src={profile.photo.url}
            fill
            className={"object-cover"}
            alt={profile.fullName}
          />
        )}
        <div
          className={clsx(
            "absolute inset-0 bg-black/30 text-white opacity-0 transition duration-300 ease-out hover:opacity-100"
          )}
        >
          <div
            className={clsx("flex h-full flex-col justify-end space-y-2 p-8")}
          >
            <div
              className={clsx(
                "heading-sm translate-y-4 opacity-0 transition delay-100 duration-300 ease-out group-hover:translate-y-0 group-hover:opacity-100"
              )}
            >
              {profile.fullName}
            </div>
            <div
              className={clsx(
                "small-caps translate-y-4 opacity-0 transition delay-150 duration-300 ease-out group-hover:translate-y-0 group-hover:opacity-100"
              )}
            >
              {profile.role}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProfileGrid;
