import Image from "@/components/WrappedImage";
import clsx from "clsx";
import Link from "next/link";
import { useInView } from "react-intersection-observer";

const EntryCard = ({
  heading,
  subHeading,
  image,
  href,
  summary = "",
  overlay = false,
  showOverlay = false,
  bgColorClass = "bg-red",
  sizes = "",
  priority = false,
}: {
  heading: string;
  subHeading?: string;
  image: any[];
  href: string;
  summary?: string;
  overlay?: boolean;
  showOverlay?: boolean;
  sizes?: string;
  priority?: boolean;
}) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  const _Caption = () => {
    return (
      <div className={clsx("space-y-4 py-8")}>
        {!!subHeading && <div className={clsx("small-caps")}>{subHeading}</div>}
        <div className={clsx("heading-md")}>{heading}</div>
        {!!summary && <div className={clsx("")}>{summary}</div>}
      </div>
    );
  };

  return (
    <Link
      href={href}
      className={clsx("EntryCard", "group block transition duration-500", {
        "translate-y-2 opacity-0": !priority && !inView,
        "translate-y-0 opacity-100": !priority && inView,
      })}
      ref={ref}
    >
      <div className={clsx("overflow-hidden", bgColorClass)}>
        {!!image && (
          <div
            className={clsx(
              "group-hover:scale-[1.0125] duration-300 ease-out relative aspect-3/2 ",
            )}
          >
            <Image
              src={image.url}
              alt={image.title}
              fill
              className={"object-cover"}
              sizes={sizes}
              priority={priority}
            />
          </div>
        )}

        <div
          className={clsx(
            "pointer-events-none absolute bottom-0 left-0 right-0 h-2/3 bg-gradient-to-b from-transparent to-black/50 text-white transition duration-300 ease-out",
            {
              "translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100":
                !showOverlay,
              hidden: !overlay,
              "hidden lg:flex": overlay,
            },
          )}
        >
          <div
            className={clsx(
              "mt-auto px-12 py-12 transition delay-150 duration-500 ease-out",
              {
                "translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100":
                  !showOverlay,
              },
            )}
          >
            <_Caption />
          </div>
        </div>
      </div>
      <div
        className={clsx("", {
          " lg:block": !overlay,
          "block lg:hidden": overlay,
        })}
      >
        <_Caption />
      </div>
    </Link>
  );
};

export default EntryCard;
