import Image from "@/components/WrappedImage";
import clsx from "clsx";

const LogoCell = ({ image, sizes = "" }) => {
  return (
    <div className={clsx("rounded p-2 transition-all sm:p-4", {})}>
      <div className={clsx("relative aspect-4/3 transition-all dark:invert")}>
        {!!image && (
          <Image
            key={image.id}
            src={image.url}
            fill
            className={"object-fit"}
            alt={image.title}
            priority
            sizes={sizes}
          />
        )}
      </div>
    </div>
  );
};

export default LogoCell;
