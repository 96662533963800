import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const AwardsList = ({ awards }) => {
  return (
    <div className={clsx("AwardsList", "container")}>
      {awards.map((award) => {
        return <_Award key={award.id} award={award} />;
      })}
    </div>
  );
};

const _Award = ({ award }) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  const _MetaItem = ({ label, value }) => {
    return (
      <div className={clsx("flex flex-col lg:flex-row")}>
        <div className={clsx("small-caps lg:w-2/6")}>{label}:</div>
        <div className={clsx("lg:w-4/6")}>{value}</div>
      </div>
    );
  };

  return (
    <div ref={ref}>
      <div
        key={award.id}
        className={clsx(
          "grid transition duration-500 grid-cols-24 items-center space-y-4 border-t border-black/20 dark:border-white/20 py-12 sm:gap-x-8 lg:space-y-0",
          {
            "translate-y-2 opacity-0": !inView,
            "translate-y-0 opacity-100": inView,
          },
        )}
      >
        <div className={clsx("col-span-24 tabular-nums lg:col-span-2")}>
          {award.year}
        </div>
        <div className={clsx("heading-lg col-span-24 lg:col-span-15")}>
          {award.heading}
        </div>
        <div className={clsx("col-span-24 lg:col-span-7")}>
          <div className={clsx("flex flex-row lg:flex-col")}>
            <div className={clsx("w-1/2 lg:w-full")}>
              <_MetaItem label="Client" value={award.client} />
            </div>
            <div className={clsx("w-1/2 lg:w-full")}>
              <_MetaItem label="Expertise" value={award.expertiseOther} />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardsList;
