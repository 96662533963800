import BodyText from "@/components/BodyText";
import ButtonLabel from "@/components/ButtonLabel";
import Target from "@/components/Target";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const TextBlock = ({ text, target, textAlignment = "left" }) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  const hasTarget = !!target && !!target.url;

  return (
    <div
      className={clsx("TextBlock", "fade-in container", {
        "opacity-0": !inView,
        "opacity-100": inView,
      })}
      ref={ref}
    >
      <div
        className={clsx(
          "TextBlock__content",
          "mx-auto flex max-w-2xl flex-col",
        )}
      >
        <div className={clsx("TextBlock__text", "text-current")}>
          <BodyText text={text} textAlignment={textAlignment} />
        </div>
        {hasTarget && (
          <Target target={target}>
            <ButtonLabel label={target.text} />
          </Target>
        )}
      </div>
    </div>
  );
};

export default TextBlock;
